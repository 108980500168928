<template>
  <el-drawer :visible.sync="visible" title="批次选择" size="80%" direction="rtl" append-to-body :wrapper-closable="false"
    @close="onClose" :append-to-body="true">
    <div class="bom flex">
      <div class="L">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-input v-model="warehouseInNumber" placeholder="入库单号" maxlength="50" clearable />
          </el-col>
          <el-col :span="6">
            <el-input v-model="purchaseNumber" placeholder="采购单号" maxlength="50" clearable />
          </el-col>
          <el-col :span="6">
            <el-input v-model="salesOrderNumber" placeholder="销售单号" maxlength="50" clearable />
          </el-col>
          <el-col :span="6">
            <div class="text-left">
              <el-button @click="search">搜索</el-button>
              <el-button @click="reset">重置</el-button>
            </div>
          </el-col>
        </el-row>

        <!-- 产品库Table -->

        <el-table ref="multipleTable" :row-key="
            (row) => {
              return `${row.lotNo}${Math.random()}`
            }
          " class="mt10" border :data="tableData" height="calc(100vh - 220px)" style="width: 100%; overflow-x: auto"
          @selection-change="selectionChange">
          <el-table-column type="selection" :reserve-selection="true" width="40" fixed="left" />
          <el-table-column align="center" label="入库单号" prop="warehouseInNumber" width="120" />
          <el-table-column align="center" label="入库批次" prop="lotNo" width="120" />
          <el-table-column align="center" label="入库类型" width="120">
            <template slot-scope="scope">
              <span>
                {{ getType(scope.row.type) }}
              </span>
            </template>
          </el-table-column>
          >
          <el-table-column align="center" label="入库仓库" prop="warehouseName" width="120" />
          <el-table-column align="center" label="采购单号" prop="purchaseNumber" width="120" :formatter="formatter" />
          <el-table-column align="center" label="采购开发" prop="buyer" width="120" :formatter="formatter" />
          <el-table-column align="center" label="销售单号" prop="salesOrderNumber" width="120" :formatter="formatter" />
          <el-table-column align="center" label="客户代表" prop="businessName" :formatter="formatter" width="120" />
          <el-table-column align="center" label="可用库存" prop="usableNum" width="120" />
          <el-table-column align="center" label="创建人" prop="creator" width="120" />
          <el-table-column align="center" label="入库时间" prop="warehouseInDate" width="120" />
        </el-table>

        <el-pagination :current-page="page.pageNo" :page-size="page.pageLe" :total="total"
          :page-sizes="[10, 20, 30, 40, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
          @current-change="pageChange" @size-change="sizeChange" />
      </div>
      <div class="M">
        <el-button icon="el-icon-right" type="primary" :disabled="selectList.length === 0" @click="leftToRight" />
      </div>
      <div class="R">
        <p class="mt10">
          产品信息：
          <span style="color: black; font-weight: bold">
            {{ sku }}/{{ productName }}
          </span>
        </p>
        <el-form ref="ruleForm" class="container" :model="form">
          <el-table class="input-table mt10" border :data="form.selected">
            <el-table-column align="center" label="入库单号" prop="warehouseInNumber" width="120" />
            <el-table-column align="center" label="入库批次" prop="lotNo" width="120" />
            <el-table-column align="center" label="采购单号" prop="purchaseNumber" width="120" :formatter="formatter" />
            <el-table-column align="center" label="入库数量" prop="inTotalNum" width="120">
              <template slot-scope="scope">
                {{ scope.row.inTotalNum || scope.row.warehouseInNum || '--' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="可用库存" prop="usableNum" width="120" />
            <el-table-column align="center" label="批次出库数量" prop="lotNumber" width="150" label-class-name="require-th"
              fixed="right">
              <template slot-scope="scope">
                <el-form-item :prop="'selected.' + scope.$index + '.lotNumber'" :rules="[
                    {
                      required: true,
                      message: '批次出库数量必填',
                      trigger: ['change', 'blur'],
                    },
                  ]">
                  <el-input-number v-model="scope.row.lotNumber" style="width: 100px" :precision="0" :min="0"
                    :max="scope.row.usableNum" :controls="false" :disabled="scope.row.processOrReturn" />
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column width="60" align="center" label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.processOrReturn" class="icon-r" size="mini" icon="el-icon-delete" circle
                  @click="del(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
          <div class="sum">
            <span class="sum1">总计</span>
            <span v-show="source != 'AllotInquire'">申请{{ msg }}数量：</span>
            <span v-show="source != 'AllotInquire'" class="sum2">
              {{ applyNum }}
            </span>
            <span>批次出库数量：</span>
            <span>{{ lotSums }}</span>
          </div>
        </el-form>
      </div>

      <div class="btns">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
  import AllotInquireInteractor from '@/core/interactors/stock-center/allot-inquire'
  import { StockInteractor, StoreCheck } from '@/core'
  import { deepCopy } from 'kits'

  export default {
    props: {
      msg: {
        type: String,
        default: '出库',
      },
      source: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        visible: false, //展示侧滑
        warehouseInNumber: '', // 产品库搜索参数
        purchaseNumber: '', //采购单号
        salesOrderNumber: '',
        page: {
          // 分页参数
          pageLe: 10,
          pageNo: 1,
        },
        total: 0, //总数量
        tableData: [], //表格数据
        selectList: [], // 本次已选中数据
        form: {
          selected: [
            // 右侧表格已添加数据
          ],
        },
        sku: '', //sku
        productName: '', // 产品名称
        applyNum: '', //申请出库数量
      }
    },
    computed: {
      lotSums() {
        return this.form.selected.reduce((prev, next) => {
          if (!isNaN(next.lotNumber)) {
            prev += next.lotNumber
          }
          return prev
        }, 0)
      },
    },

    methods: {
      formatter(row, column, cellValue, index) {
        return cellValue ? cellValue : '--'
      },
      // 重置
      reset() {
        this.warehouseInNumber = ''
        this.purchaseNumber = ''
        this.salesOrderNumber = ''
        this.form.selected = []
        this.$refs.multipleTable.clearSelection()
        this.search()
      },
      /**
       * 获取类型
       */
      getType(type) {
        const type2text = {
          0: '采购入库',
          1: '退货入库',
          2: '其他入库',
          10: '调拨入库',
          11: '盘盈入库',
          12: '调拨出库',
          13: '盘点出库',
          14: '退料入库',
          15: '加工入库',
        }
        return type2text[type]
      },

      /**
       * 显示侧滑
       * @ params {object} option
       * option.list //已选中数据
       */
      async show(option = {}) {
        // 添加orderProductUniqueId
        const {
          list,
          sku,
          warehouseId,
          productName,
          applyNum,
          orderCode,
          useType,
          orderProductUniqueId,
          salesOrderNumber,
          processOut,
        } = option

        this.sku = sku
        this.productName = productName // 产品信息产品名称
        this.warehouseId = warehouseId // 仓库id
        this.applyNum = applyNum // 申请出库数量
        this.orderCode = orderCode
        this.useType = useType
        this.salesOrderNumber = salesOrderNumber
        this.orderProductUniqueId = orderProductUniqueId
        this.processOut = processOut
        if (Array.isArray(list)) {
          // 切断与父组件的引用关系
          this.form.selected = deepCopy(list)
        }
        this.getList()
        this.visible = true
      },

      /**
       * 盘点查询-显示侧滑
       */
      async stockCheckshow(option = {}) {
        const {
          sku,
          usable,
          warehouseId,
          warehouseLocationId,
          productName,
          applyNum,
          orderCode,
          useType,
          list,
          tenantId,
        } = option

        this.sku = sku
        this.productName = productName // 产品信息产品名称
        this.warehouseId = warehouseId // 仓库id
        this.applyNum = applyNum // 申请出库数量
        this.orderCode = orderCode
        this.useType = useType
        // this.salesOrderNumber = salesOrderNumber
        // this.orderProductUniqueId = orderProductUniqueId
        // this.processOut = processOut
        if (Array.isArray(list)) {
          this.form.selected = list
        }

        this.getList({
          sku,
          tenantId,
          usable,
          warehouseId,
          warehouseLocationId,
        })
        this.visible = true
      },

      /**
       * 调拨查询-显示侧滑
       */
      allotShow(row) {
        this.sku = row.sku
        this.warehouseId = row.warehouseId // 仓库id
        if (Array.isArray(row.list)) {
          this.form.selected = row.list
        }
        setTimeout(() => {
          // 获取批次id
          if (row.ids?.length) {
            let [ids, _list] = [[], []]
            row.ids.forEach((i) => ids.push(i.stockLotId))

            this.tableData.forEach((item, i) => {
              if (ids.includes(item.stockLotId)) {
                _list.push({
                  ...item,
                  lotNumber: row.ids[i].num,
                })
              }
            })
            this.form.selected = _list
          }
        }, 500)

        this.getList()
        this.visible = true
      },

      // 确定
      save() {

        if (!this.form.selected || this.form.selected.length === 0) {
          return this.$message.warning('至少选择一条数据')
        }
        this.$refs.ruleForm.validate((validate) => {
          if (validate) {
            if (
              this.applyNum != this.lotSums &&
              this.source != 'AllotInquire'
            ) {
              return this.$message.warning(
                `批次出库数量不等于申请${this.msg}数量`
              )
            } else {
              this.visible = false
              this.$emit('lotlist-set', this.form.selected)
              this.close()
            }
          }
        })
      },

      // 取消
      close() {

        this.visible = false
        this.reset()
      },

      // 删除右侧列表数据
      del(index) {
        const { selected } = this.form
        // if (selected.length === 1) {
        //   return this.$message.warning('至少保留一条数据')
        // }
        selected.splice(index, 1)
      },

      // 产品库搜索
      search() {
        this.page.pageLe = 10
        this.page.pageNo = 1
        this.$refs.multipleTable.clearSelection()
        this.getList()
      },
      selectionChange(list) {
        this.selectList = list
      },
      // 添加到右边
      leftToRight(list) {
        const selectedLotNos = this.form.selected.map(
          (i) => `${i.warehouseInNumber}${i.lotNo}`
        )
        const hasSelected = this.selectList.find((item) =>
          selectedLotNos.includes(`${item.warehouseInNumber}${item.lotNo}`)
        )
        if (hasSelected) {
          return this.$message.warning(
            `入库批次${hasSelected.lotNo}已存在,请勿重复勾选`
          )
        }
        this.form.selected.push(...this.selectList)
        this.$refs.multipleTable.clearSelection()
      },

      // 设置选中
      setChecked() { },

      /**
       * 获取列表
       * @param {source} 入口来源
       */
      async getList(data) {
        let res
        if (this.source === 'AllotInquire') {
          const {
            warehouseInNumber,
            purchaseNumber,
            salesOrderNumber,
            sku,
            warehouseId,
            orderProductUniqueId,
          } = this
          res = await AllotInquireInteractor.stockLotSearchListPage({
            warehouseInNumber,
            purchaseNumber,
            salesOrderNumber,
            sku: sku,
            warehouseId: warehouseId,
            orderProductUniqueId,
            ...this.page,
          })
        } else if (this.source === 'stockCheck') {
          res = await StoreCheck.locationProductLotApi({
            ...data,
            ...this.page,
          })
        } else {
          const {
            warehouseInNumber,
            purchaseNumber,
            salesOrderNumber,
            sku,
            orderCode,
            warehouseId,
            useType,
            orderProductUniqueId,
            processOut,
          } = this
          // 添加orderProductUniqueId
          res = await StockInteractor.stockLotSearchListPage({
            warehouseInNumber,
            purchaseNumber,
            salesOrderNumber,
            ...this.page,
            sku,
            warehouseId,
            orderCode,
            useType,
            orderProductUniqueId,
            processOut,
          })
        }

        if (res && res.code === '000000') {
          this.tableData = res.data.data.map((item) => {
            item.skuId = item.sku
            return item
          })
          this.total = res.data.total
        }
      },

      // 产品库分页切换
      pageChange(val) {
        this.page.pageNo = val
        this.getList()
      },

      // 产品库页码切换
      sizeChange(val) {
        this.page.pageLe = val
        this.getList()
      },
      onClose() {
        this.visible = false
      },
    },
  }
</script>

<style scoped lang="scss">
  .sum {
    margin-top: 10px;
    text-align: right;

    span {
      margin-right: 1px;

      &.sum1 {
        margin-right: 15px;
      }

      &.sum2 {
        margin-right: 20px;
      }
    }
  }

  .bom {
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
    position: relative;

    .L {
      width: 58%;
      padding: 0 10px 0 20px;
      /* border-right: 1px solid #ededed; */
    }

    .M {
      display: flex;
      align-items: center;
    }

    .R {
      width: calc(42% - 50px);
      padding: 0 20px;
    }

    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      text-align: right;
      padding: 14px 20px;
      border-top: 1px solid #ededed;
    }

    .el-tabs {
      .el-button {
        height: 34px !important;
      }

      .el-table {
        height: calc(100vh - 220px);
        overflow-y: auto;
      }

      .el-pagination {
        height: 40px;
      }
    }

    .el-form {
      height: calc(100vh - 149px);
      overflow-y: auto;
    }

    .img-wrap {
      position: relative;
      padding: 3px 0;
      width: 50px;
      margin: 0 auto;

      .el-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        display: block;
      }

      .el-badge {
        position: absolute;
        top: 3px;
        right: -10px;
        z-index: 2;
      }
    }
  }
</style>
